.render-people {
    width: 100%;
    height: 100%;
}

.render-people .profile-picture {
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.render-people .name {
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

.render-people .username {
    color: #808080;
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
}

.render-people .badge-box {
    display: flex;
    justify-content: center;
    margin-top: -10px;
}

.render-people .button-box {
    display: flex;
    justify-content: space-between; 
    margin-top: 10px;
    padding-right: 15px;
}

.render-people .button-box .button {
    width: 45%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 14px !important;
}


