.loginform::-webkit-scrollbar {
    display: none;
}


/* PHONE */
@media only screen and (max-width: 992px) {
    .loginform {
        width: 100%;
        overflow-y: scroll;
        height: 100vh;
    }
}


/* DESKTOP */
@media only screen and (min-width: 992px) {
    .loginform {
        width: 30%;
    }
}


.loginform {
    display: wrap;
    justify-content: center;
    position: fixed;
    overflow-y: scroll;
    height: 100vh;
}

.loginform .container {
    flex-wrap: wrap;
    width: 65%;
    text-align: center;
}

.loginform .container .logo-myopinion {
    width: 230px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 10px;
}

.loginform .container .error-message {
    color: red;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5%;
}

.loginform .container .forget-password-text {
    font-size: 13px;
    color: #01195f;
}

.loginform .container .connect-with {
    font-size: 13px;
    color: gray;
    text-align: center;
    margin-top: 10px;
}

.loginform .container .social {
margin-left: -10px;
width: 99%;
}

.loginform .container a {
    color: #222;
}

.loginform .container .select-language {
    font-size: 13px;
    margin-top: 6%;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.loginform .container .terms-conditions {
    margin-bottom: 5%;
}


.loginform .container .terms-conditions .policy {
    font-size: 13px;
    font-weight: 500;
    color: #808080;
}


.loginform .container .copyrigth {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
    padding-bottom: 3%;
}

