body {
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    display: none;
}

/* PHONE */
@media only screen and (max-width: 992px) {}


/* DESKTOP */
@media only screen and (min-width: 992px) {}


.login {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}


/* CARD OPINION */
.login .card-opinion {
    width: 40.484%;
}

.login .card-opinion .title {
    text-align: left;
    padding: 2%;
    border-left: 0.5px #c0c0c0 solid;
    border-right: 0.5px #c0c0c0 solid;
    border-bottom: 0.5px #c0c0c0 solid;
}

/* CARD STORE */
.login .card-store {
    width: 29%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
}

/* LOGIN CARD */
.login .card-login {
    width: 29%;
}



/* MOBILE RENDER */
.mobile-login {
    padding-bottom: 30%;
    overflow-y: scroll;
    height: 100vh;
}

.mobile-login .store {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom: 0.5px #c0c0c0 solid;
}

.store .container-logo {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 50%;
}

.store .title {
    width: 50%;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 600;
}

.store .container-logo .img-playstore {
    width: 75px;
}

.store .container-logo .img-appstore {
    width: 75px;
}

.store .container-logo .img-appgallery {
    width: 75px;
}