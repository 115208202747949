.messageContainer::-webkit-scrollbar {
    width: 15px;
}

.messageContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
}

.messageContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
}

.messageContainer::-webkit-scrollbar-thumb:hover {
    background: #6e6d6d;
}

.container {
    height: 92vh;
    background-color: white;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: white;
    border-bottom: 1px #f1f1f1 solid;
}

.backButtonHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
    border-radius: 20px;
}

.backButtonHeader:hover {
    background-color: white;
}

.menuButtonHeader {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: 25px
}

.messageContainer {
    display: flex;
    flex-direction: column-reverse;
    height: 75vh;
    overflow-y: hidden;
    border-bottom: 1px #c0c0c0 solid;
    background-color: white;
}

.inputMessage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px #f1f1f1 solid;
}

.inputMessage input[type="text"] {
    margin-top: 30px;
}