.container {
    background-color: white;
}

.titleInput {
    color: #01195f;
    font-size: 15px;
    font-weight: bold;
}

.selectInput {
    width: 100%;
    margin-top: 5px;
}

