.endbar {
    width: 100%;
    height: 100vh;
    background-color: white;
}

.endbar .header-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-bottom: 1px #f1f1f1 solid;

}

.endbar .header-box .title {
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    margin-top: 8px;
}