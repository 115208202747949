.opinion-details{
    background-color: white;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

.opinion-details .content-box {
    height: 100vh;
    padding-bottom: 100px;
    overflow-y: scroll;
}