.page {
    height: 100vh;
    background-color: white;
}

.container {
    height: 92vh;
}

.calendar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.eventContainer {
    height: 51vh;
    overflow-y: auto;
    padding-top: 20px;
}

.titleEventContainer {
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px #b1aeae solid;
}


.emptyContainer {
    padding-top: 15vh;
}

.emptyContent {
    display: flex;
    justify-content: center;
}

.iconEmptyContent {
    width: 50px;
    height: 50px;
    color: #808080;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.textEmptyContent {
    font-size: 14px;
    text-align: center;
    color: #808080;
    font-weight: 600;
    margin-top: 10px;
}