.ButtonFacebook {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 5px;
    background-color: #3b5998;
}

.ButtonFacebook .icon {
    width: 20px;
    margin-right: 10px;
}

.ButtonFacebook .text {
    font-size: 13px;
    color: white;
}

.ButtonFacebook .spinner {
    color: #1880a1;
}
