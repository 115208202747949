.home-opinions::-webkit-scrollbar {
    width: 15px;
}

.home-opinions::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
}

.home-opinions::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
}

.home-opinions::-webkit-scrollbar-thumb:hover {
    background: #6e6d6d;
}

.home-opinions {
    width: 99.5%;
    height: 92vh;
    margin-left: 2.5px;
    overflow-y: auto;
    overflow-x: hidden;
}

.home-opinions .title-page {
    text-align: center;
    color: #414141;
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px #f1f1f1 solid;
}