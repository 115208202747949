.opinion-share{
    width: 97%;
    border: 1px #dfdcdc solid;
    background-color: white;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.opinion-share .user-container {
    display: flex;
    align-items: center;
    padding: 15px;
}

.opinion-share .user-container .name-container{
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}

.opinion-share .user-container .picture-profile {
    width: 60px;
    height: 60px;
    border: 2px #1880a1 solid;
    border-radius: 30px;
}

.opinion-share .user-container .name{
    font-size: 18px;
    margin: 0px;
}

.opinion-share .user-container .username{
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    margin: 0px;
}


.opinion-share .sond-picture {
    width: 100%;
    border-radius: 10px;
}

.opinion-share .sond-caption {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
}

.opinion-share .timestamp-container{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.opinion-share .footer-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 10px;
    border-top: 1px #f1f1f1 solid;
}