.topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    color: #fff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-bottom: 1px #f1f1f1 solid;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .menu-icon {
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .menu {
    display: none;
  }
  
  .menu.show {
    display: block;
  }
  
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu li {
    margin-right: 1rem;
  }
  
  .menu a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  