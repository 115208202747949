.containerAccount {
    margin-bottom: 10px;
}

.titleAccount {
    color: #222;
    font-size: '13px';
    font-weight: 'bold';
}

.addAccountButton {
    margin-top: 5px;
}