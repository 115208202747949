.home {
    display: flexbox;
    width: 100%;
    height: 80vh;
    background-color: white;
}

.home .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px #f1f1f1 solid;
}

.home .header .title {
    color: #414141;
    font-weight: bold;
    text-align: center;
}