.profile {
    width: 100%;
    background-color: white;
}

.profile .header-profile {
    height: 50px;
    background-color: white;
}

.profile .content {
    width: 50%;
    overflow-y: scroll;
}

.profile .content .topbar {
    display: flex;
    align-items: center;
    position: fixed;
    border: 1px #f1f1f1 solid;
    width: 45.4%;
    height: 50px;
    background-color: white;
    padding-left: 10px;
}

.profile .content .topbar .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.profile .content .topbar .button-back:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.profile .content .topbar .title {
    width: 80%;
    text-align: center;
    margin-left: 15px;
    margin-top: 5px;
}


.profile .content .content-body {
    margin-top: 50px;
    padding-bottom: 50px;
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}

.profile .content .content-body .cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px #f1f1f1 solid;
}

.profile .content .content-body .picture {
    display: flex;
    margin-left: 50px;
    margin-top: -70px;
}

.profile .content .content-body .picture .account {
    margin-top: 80px;
    margin-left: 20px;
}

.profile .content .content-body .description {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.profile .content .content-body .description .box-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile .content .content-body .button-box {
    display: flex;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.profile .content .content-body .button-box .button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #1880a1; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
    /* color: white; */
    text-align: center;
    font-weight: 600;
    font-size: 14px !important;
}

.profile .content .content-body .audience {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    border-radius: 5px;
    background-color: #f5f5f5;
}