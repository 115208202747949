.forget-password {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: white;
}

 /* FORM CARD */
 .forget-password .card-form {
    width: 350px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;

}

.forget-password .card-form .title{
    color: #222;
    text-align: center;
    font-size: 24px;
}

.forget-password .card-form .subtitle{
    color: #808080;
    text-align: center;
    font-size: 16px;
}

.forget-password .card-form .error-message {
    color: red;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5%;
}


.forget-password .card-form form {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
}

.forget-password .card-form .error-message {
    color: red;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5%;
}
