.appLayout {
    display: flex;
    height: 100vh;
}

.outlet {
    width: 50%;
    height: 100vh;
    background-color: rgb(252, 249, 249);
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}

.sidebar {
    width: 25%;
    height: 100vh;
}

.endbar {
    width: 25%;
    height: 100vh;
}

button {
    background-color: white;
    color: #404040;
    border: none;
    font-size: 16px !important;
    margin-left: 10px;
}