.screen {
    height: 95vh;
    background-color: white;
}

.container {
    padding: 30px;
}

.titleInput {
    color: #01195f;
    font-size: 15px;
    font-weight: bold;
}

.inputContainer {
    margin-bottom: 20px;
}

.textError {
    color: red;
    font-size: 12px;
    font-weight: 500;
}