.container {
    height: 100vh;
    background-color: white;
}

.content {
    height: 100vb;
    overflow-y: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 200px;
}

.loadingContent {
    display: flex;
    justify-content: center;
    padding-top: 30vh;
}

.containerPending {
    margin-top: 20px;
}

.titlePending {
    font-size: 15px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}