.senderMessageContainer{
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.receiverMessageContainer{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.senderMessageText {
    font-size: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    background-color: #234a83;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.receiverMessageText {
    font-size: 14px;
    font-weight: 500;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    color: #222;
    background-color: #c9c9c9;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.senderMessagetimestamp {
    font-size: 11px;
    /* font-weight: 600; */
    color: #f5f5f5;
}

.receiverMessagetimestamp {
    font-size: 11px; 
    font-weight: 500;
    color: #5c5b5b;
    text-align: right;
}



