/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .register .card-form .title {
        margin-top: 0px;
    }
}

/* PHONE */
@media only screen and (max-width: 992px) {

    /* FORM CARD */
    .register .card-form {
        width: 80%;
        text-align: center;
    }

    .register .card-form form input {
        width: 98%;
        margin-left: auto;
        margin-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .register .card-form .social {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
    }

}


/* DESKTOP */
@media only screen and (min-width: 992px) {

    /* FORM CARD */
    .register .card-form {
        width: 25%;
        text-align: center;
    }

    .register .card-form form input {
        width: 100%;
        margin-bottom: 5%;
    }

    .register .card-form .social {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
    }

}

.register {
    display: flex;
    justify-content: center;
    background-color: white;
    margin-bottom: 50px;
}

.register .card-form .title {
    color: #222;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    margin-top: -20px;
}

.register .card-form .subtitle {
    color: #808080;
    font-size: 16px;
    text-align: center;
}

.register .card-form .drop-select {
    width: 50%;
    font-size: 14px;
}

.register .card-form .error-message {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5%;
}

.register .card-form .input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.register .card-form .language {
    margin-bottom: 5%;
    font-size: 14px;
}

.register .card-form .policy {
    font-size: 11px;
}

.register .card-form .copyrigth {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #808080;
}