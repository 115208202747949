.screen {
    height: 100vh;
    background-color: white;
}

.content {
    padding-top: 20px;
    padding-bottom: 100px;
    overflow-y: auto;
}

.containerSearch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.inputSearch {
    width: 88%;
}

.searchIcon {
    width: 25px;
    height: 25px;
}



.emptyContainer {
    text-align: center;
    padding-top: 25vh;
}

.emptyIcon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
}
.textEmpty {
    color: #808080;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}