.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-right: 15px;
}

.containerButton {
    width: 43%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.button {
   font-size: 10px;
}

.textButton {
    font-weight: 600;
    font-size: 10px;
}