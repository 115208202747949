.screen {
    height: 95vh;
    background-color: white;
}

.tablist {
    justify-content: space-evenly;
}
.tablabel {
    font-size: 13px;
    font-weight: 600;
}