.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
  }
  
  .bottom-nav ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #ffffff;
  }
  
  .bottom-nav li {
    flex: 1;
    text-align: center;
    /* border-right: 1px solid #ccc; */
  }
  
  .bottom-nav li:last-child {
    border-right: none;
  }
  
  .bottom-nav a {
    display: block;
    padding: 16px;
    text-decoration: none;
    color: #333;
  }
  