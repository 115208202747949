.container {
    height: 100vh;
    background-color: white;
}

.content {
    height: 100vh;
    padding-top: 20px;
    padding-bottom: 100px;
    overflow-y: scroll;
}

.loadingContent {
    display: flex;
    justify-content: center;
    padding-top: 30vh;
}

.containerAccount {
    margin-bottom: 10px;
}

.titleAccount {
    color: #234a83;
    font-size: 14px;
    font-weight: bold;
}

.addAccountButton {
    width: 58px;
    height: 55px;
    margin-top: 15px;
    margin-left: 0px;
    border: 1px #234a83 solid;
    border-radius: 5px;
}


.containerOption {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    cursor: pointer;
}


.textContainerOption {
   margin-left: 20px;
    margin-right: 20px;
}

.textOption {
    font-size: 15px;
    color: #414141;
    font-weight: 600;
    margin: 0px;
}

.subtextOption {
    font-size: 14px;
    color: #808080;
    font-weight: 500;
    margin: 0px;

}

.iconOption {
    color: #414141;
    width: 25px;
    height: 25px;
}