.container {
    height: 98vh;
    background-color: white;
}

.content {
    width: 100%;
    height: 95vh;
    overflow-y: scroll;
}

.loadingContent {
    display: flex;
    justify-content: center;
    padding-top: 30vh;
}

.emptyContainer {
    padding-top: 30vh;
}

.emptyContent {
    display: flex;
    justify-content: center;
}

.iconEmptyContent {
    width: 80px;
    height: 80px;
    color: #C0C0C0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

}

.textEmptyContent {
    text-align: center;
    color: #C0C0C0;
    font-weight: 600;
}