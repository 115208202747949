.view-opinion {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: white;
}

.view-opinion .content {
    width: 50%;
    overflow-y: scroll;

}

.view-opinion .content .topbar {
    display: flex;
    align-items: center;
    position: fixed;
    border: 1px #f1f1f1 solid;
    width: 45.4%;
    height: 50px;
    background-color: white;
    padding-left: 10px;
} 

.view-opinion .content .topbar .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.view-opinion .content .topbar .button-back:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.view-opinion .content .topbar .title {
    width: 80%;
    text-align: center;
    margin-left: 15px;
    margin-top: 5px;
}

.view-opinion .content .view {
    margin-top: 50px;
    padding-bottom: 50px;
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}
