.containerItem {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
    margin-right: 5%
}

.item {
    display: flex; 
    align-items: center;
    border: 1px #696969 solid;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 8px;
}

.iconItem {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 5px;
}

.textItem {
    font-size: 14px;
    margin-right: 10px;
}