.screen {
    height: 100vh;
    background-color: white;
}

.content {
    height: 94vh;
    background-color: white;
    overflow: 'auto',
}

.loadingContainer {
    text-align: center;
    padding-top: 35vh;
}

.loadingText {
    margin-top: 10px;
}

.emptyIcon {
    width: 60px;
    height: 60px;
}

.emptyText {
    color: #808080;
    margin-top: 10px;
    margin-right: 20%;
    margin-left: 20%;
}