
.container {
    display: flex;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.container:hover {
   background-color: #F1F1F1;
}

.icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.title {
    font-weight: 600;
   margin-bottom: 0px;
}

.description {
    font-size: 15px;
    color: #808080;
    margin: 0px;
}

.document {
    font-size: 14px;
    font-weight: 500;
    color: #414141;
    margin: 0px;
}

.status {
    font-weight: 600;
}