.ButtonGmail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border: 0.5px #969696 solid;
    border-radius: 5px;
}

.ButtonGmail .icon {
    width: 20px;
    margin-right: 10px;
}

.ButtonGmail .text {
    font-size: 13px;
    color: gray;
}

.ButtonGmail .spinner {
    color: #1880a1;
}
