.screen {
    height: 100vh;
    background-color: white;
}

.container {
    overflow-y: auto;
    height: 92vh;
    padding-bottom: 50px;
}

.detail {
    display: flex;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.title {
    font-weight: 600;
   margin-bottom: 0px;
}

.description {
    font-size: 15px;
    color: #808080;
    margin: 0px;
}

.document {
    font-size: 14px;
    font-weight: 500;
    color: #414141;
    margin: 0px;
}

.status {
    font-weight: 600;
}

.detailContainer{
    padding-left: 20px;
    padding-right: 20px;
}

.box {
   display: flex; 
   justify-content: space-between
}

.label {
    width: 45%;
    color: #808080;
    font-weight: 400;
    margin-bottom: 8px;
}

.data {
    width: 55%;
    font-weight: 500;
    margin-bottom: 8px;
}

.titleInput {
    color: #01195f;
    font-size: 15px;
    font-weight: bold;
    margin: 0px;
}